import React from 'react';
import DatePicker from 'react-date-picker';
import ChangeHighlight from 'react-change-highlight';

const formatCurrency = (amount) => {
  return ((amount || 0)/100000).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

const formatNumber = (amount) => {
  if (amount) {
    return amount.toLocaleString('en-US', { });
  }
  return 0;
};

const sumMetrics = (appsMetrics) => {
  var totalMetrics = {};
  if (Array.isArray(Object.keys(appsMetrics))) {
    for (const bundleId of Object.keys(appsMetrics)) {
      const appDayMetrics = appsMetrics[bundleId]["metrics"];
      for (const day of Object.keys(appDayMetrics)) {
        if (!totalMetrics[day]) {
          totalMetrics[day] = {};
        }
        for (const metricKey of Object.keys(appDayMetrics[day])) {
          totalMetrics[day][metricKey] = (totalMetrics[day][metricKey] ? totalMetrics[day][metricKey] : 0) + appDayMetrics[day][metricKey];
        }
      }
    }
  }
  return totalMetrics;
};

const ShownCard = ({cardKey, metrics, metricKey, title, reportDate, days, editMode, hidden, toggleHidden, currency = false, minusMetric = null}) => (
  <div className="w-full" id={cardMetricKey(cardKey, metricKey)}>
    <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
      <div className="text-lg w-max text-gray-700 dark:text-white font-semibold pb-4 inline-flex">
      { editMode ? (
          isHidden(hidden, cardMetricKey(cardKey, metricKey)) ? 
            (
              <a className="cursor-pointer" onClick={(event) => toggleHidden(cardMetricKey(cardKey, metricKey))}>
                <div className="mr-5">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </a>
              ) : (
              <a className="cursor-pointer" onClick={(event) => toggleHidden(cardMetricKey(cardKey, metricKey))}>
                <div className="mr-5">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </a>
            ) 
          ) :
          ( <div/> )
      }
      {title}
      </div>
    {Object.keys(metrics).sort().reverse().map((date, index) => {
      return (
        date === reportDate ? (
          <div key={index}>
            <p className="text-sm w-max text-gray-700 dark:text-white font-bold">
            {date}
            </p>
              <ChangeHighlight highlightClassName="highlighter">
                <div className="flex items-end space-x-2 my-6 text-5xl text-black dark:text-white font-bold inline-table" ref={React.createRef()}>
                  {minusMetric ?
                    (currency ? formatCurrency(metrics[date][metricKey] - metrics[date][minusMetric]) : formatNumber(metrics[date][metricKey] - metrics[date][minusMetric])) :
                    (currency ? formatCurrency(metrics[date][metricKey]) : formatNumber(metrics[date][metricKey]))
                  }
                </div>
              </ChangeHighlight>
          </div>
          ) : index < days ? (
            <div className="dark:text-white" key={index}>
              <div className="flex items-center pb-2 mb-2 text-sm sm:space-x-12 justify-between border-b border-gray-200">
                <p>{date}</p>
                <ChangeHighlight highlightClassName="highlighter">
                <div className="flex items-end text-xs" ref={React.createRef()}>
                  {minusMetric ?
                    (currency ? formatCurrency(metrics[date][metricKey] - metrics[date][minusMetric]) : formatNumber(metrics[date][metricKey] - metrics[date][minusMetric])) :
                    (currency ? formatCurrency(metrics[date][metricKey]) : formatNumber(metrics[date][metricKey]))
                  }
                </div>
                </ChangeHighlight>
              </div>
            </div>
        ) : (<div/>)
      )
    })}
    </div>
  </div>
);

const Card = ({cardKey, metrics, metricKey, title, reportDate, days, editMode, hidden, toggleHidden, currency = false, minusMetric = null}) => {
  return (!editMode && isHidden(hidden, cardMetricKey(cardKey, metricKey))) ? 
    (<></>) : 
      isHidden(hidden, cardMetricKey(cardKey, metricKey)) ? 
      ( <div className="border-4 border-dotted"><ShownCard cardKey={cardKey} metrics={metrics} metricKey={metricKey} title={title} reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} currency={currency} minusMetric={minusMetric} /></div> ) :
      ( <ShownCard cardKey={cardKey} metrics={metrics} metricKey={metricKey} title={title} reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} currency={currency} minusMetric={minusMetric} /> );
};

const ReportDate = ({ loading, reportDate, changeDate, dateFormat }) => {
  return loading ?
    <>Loading...</> :
    <>Report for <DatePicker onChange={(day) => changeDate(dateFormat(day))} format="y-MM-dd" closeCalendar={true} clearIcon={null} locale="en-US" value={new Date(Date.parse(reportDate+"T00:00:00-0800"))}/></>
 };

const isHidden = (hidden, id) => {
  return hidden[id];
}

const cardMetricKey = (cardKey, metricKey) => {
  return cardKey + "-" + metricKey;
};

const AppCards = ({ cardKey, name, metrics, reportDate, index, days, editMode, hidden, toggleHidden }) => (
  <div key={index}>
    <p className="text-4xl text-black dark:text-white font-bold mt-10">
      {name}
    </p>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-4">
      <Card cardKey={cardKey} metrics={metrics} metricKey="installs" title="Installs" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} />
      <Card cardKey={cardKey} metrics={metrics} metricKey="purchases" title="Purchases" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} />
      <Card cardKey={cardKey} metrics={metrics} metricKey="refunds" title="Refunds" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} />
      <Card cardKey={cardKey} metrics={metrics} metricKey="revenue" title="Revenue" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} currency="true" />
      <Card cardKey={cardKey} metrics={metrics} metricKey="amount" title="Proceeds" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} currency="true" />
      <Card cardKey={cardKey} metrics={metrics} metricKey="amount" title="Net Proceeds" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} currency="true" minusMetric="refundamt" />
      <Card cardKey={cardKey} metrics={metrics} metricKey="trials" title="Trials" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} />
      <Card cardKey={cardKey} metrics={metrics} metricKey="renewals" title="Renewals" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} />
      <Card cardKey={cardKey} metrics={metrics} metricKey="cancels" title="Cancellations" reportDate={reportDate} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} />
    </div>
  </div>
);

const Dashboard = ({ title, data, days, sandbox, reportDate, websocketStatus, changeDays, changeSandbox, changeDate, dateFormat, editMode, toggleEditMode, hidden, toggleHidden, logoutURL }) => (
  <main className="bg-gray-100 dark:bg-gray-800 h-screen relative pb-10">
  <div className="float-right">
    { (websocketStatus === 'CONNECTED') ?
      (
        <div className="bg-green-100 w-full p-4 mt-2 shadow-lg flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
          </svg>
          <span className="pl-2">Live</span>
        </div>
      ) :
      (websocketStatus === 'DISCONNECTED') ? (
        <a className="cursor-pointer" onClick={() => {window.location.href=window.location.href}}>
          <div className="bg-red-100 w-full p-4 mt-2 shadow-lg flex">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            <span className="pl-2">Dead</span>
          </div>
        </a>
      ) :
      (
        <a className="cursor-pointer" onClick={() => {window.location.href=window.location.href}}>
          <div className="bg-yellow-100 w-full p-4 mt-2 shadow-lg flex">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
            </svg>
            <span className="pl-2">...</span>
          </div>
        </a>
      )
    }
    <div className="w-full p-4 mt-2 shadow-lg flex items-center text-blue-700">
      <a className="cursor-pointer w-full flex items-center" onClick={() => {toggleEditMode()}}>{ editMode ? (<button className="flex-auto">Done</button>) : (<button className="flex-auto">Edit</button>) }</a>
    </div>
    { logoutURL ? 
      (
        <div className="w-full p-4 mt-2 shadow-lg flex items-center text-blue-700">
          <a className="cursor-pointer w-full flex items-center" href={logoutURL}><button className="flex-auto">Logout</button></a>
        </div>
      ) :
      ( <div/> )
    }
  </div>
  <div className="float-right">

  </div>
  <p className="text-5xl text-black dark:text-white font-bold">
    {title}
  </p>
  <div className="text-4xl text-black dark:text-white mt-10">
    <ReportDate loading={Object.keys(data.apps).length == 0} reportDate={reportDate} changeDate={changeDate} dateFormat={dateFormat} />
  </div>
  <p className="text-2xl text-black dark:text-white mt-2">
    Days:
    <select value={days} className="ml-2 dark:bg-gray-700" onChange={(event) => changeDays(event.target.value)}>
      { [...Array(13)].map((e, i) => (<option key={i+2} value={i+2}>{i+2}</option>)) }
    </select>
    &nbsp;&nbsp;Sandbox:
    <select value={sandbox || false} className="ml-2 dark:bg-gray-700" onChange={(event) => changeSandbox(event.target.value)}>
      <option key="0" value="">False</option>
      <option key="1" value="1">True</option>
    </select>
  </p>
  { Object.keys(data.apps).length > 0 ?
    (<AppCards cardKey="Total" name="Total" metrics={sumMetrics(data.apps)} reportDate={reportDate} index="-1" key="-1" days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} />) : 
    ( <div/> )
  }
  { Object.keys(data.apps).map((bundleId, index) => (
    <AppCards cardKey={bundleId} name={data.apps[bundleId].name} metrics={data.apps[bundleId].metrics} reportDate={reportDate} index={index} key={index} days={days} editMode={editMode} hidden={hidden} toggleHidden={toggleHidden} />
  ))}
  </main>
);

export default Dashboard;
